import { Button, Input, Textarea, toast } from "@/components/ui";
import { Collection } from "@/prisma/schema/mysql";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { SelectedCollection } from "./CollectionSelection";
import {
  AlignLeft,
  FolderIcon,
  PlusCircle,
  PlusCircleIcon,
} from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { collectionInput } from "@/types/collectionInputType";
import { useForm } from "react-hook-form";
import { z, ZodError } from "zod";
import { useTranslation } from "react-i18next";
import { error } from "console";
import { useTranslatedErrors } from "@/lib/i18n/translationUtils";
import { PlusIcon } from "@heroicons/react/24/solid";

interface CreationFooterProps {
  setCollections: React.Dispatch<React.SetStateAction<SelectedCollection[]>>;
  setPageIndex: (val: number) => void;
  collections: SelectedCollection[];
  pageSize: number;
  setIsOpen: (val: boolean) => void;
}
type CollectionFormData = z.infer<typeof collectionInput>;

export default function CreationFooter({
  setCollections,
  setPageIndex,
  collections,
  pageSize,
  setIsOpen,
}: CreationFooterProps) {
  const [isCreating, setIsCreating] = useState(false);
  const { t } = useTranslation(["collections", "zodErrors"]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    ...form
  } = useForm<CollectionFormData>({
    resolver: zodResolver(collectionInput),
    mode: "onSubmit",
  });

  const cancelCreating = () => {
    setIsCreating(false);
    reset();
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Escape") {
      cancelCreating();
    }
  };
  const { isLoading, mutate } = useMutation({
    mutationFn: async (data: CollectionFormData) => {
      const res = await axios.post("/api/collections", data);
      return res.data;
    },
    onSuccess: (data: Collection) => {
      setCollections((prev) => {
        const newCollections = [
          {
            ...data,
            selected: false,
          },
          ...prev,
        ];
        if (newCollections.length > pageSize) {
          newCollections.pop();
        }
        return newCollections;
      });
      setPageIndex(1); //collections are shown asc createdAt
      setIsCreating(false);
    },
    onError: (error: Error) => {
      console.log(error);
      toast({
        description: t("creationFooter.error"),
        variant: "destructive",
      });
    },
  });

  const translatedErrors = useTranslatedErrors(errors, t);

  const onSubmit = (data: CollectionFormData) => {
    mutate(data);
  };

  return (
    <div className="mt-3 sm:mt-6">
      <div className="space-y-3 sm:space-y-4">
        {isCreating ? (
          <div>
            <form
              className="space-y-2 sm:space-y-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="space-y-2">
                <div className="relative">
                  <input
                    placeholder={t("creationFooter.form.namePlaceholder")}
                    {...register("name")}
                    className="resize-none w-full h-8 sm:h-10 pl-7 sm:pl-9 pr-2 sm:pr-4 text-xs sm:text-sm border rounded-lg
                    focus:outline-none"
                  />
                  <FolderIcon
                    size={14}
                    className="absolute left-2 sm:left-3 top-2 sm:top-3 text-slate-400"
                  />
                  {translatedErrors.name && (
                    <p className="mt-1 text-xs text-red-500">
                      {translatedErrors.name.message}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <input
                    {...register("description")}
                    placeholder={t(
                      "creationFooter.form.descriptionPlaceholder",
                    )}
                    onKeyDown={handleKeyPress}
                    className="w-full pl-7 sm:pl-9 pr-2 sm:pr-4 py-1.5 sm:py-2 text-xs sm:text-sm border rounded-lg
                    focus:outline-none resize-none"
                  />
                  <AlignLeft
                    size={14}
                    className="absolute left-2 sm:left-3 top-2 sm:top-3 text-slate-400"
                  />
                  {translatedErrors.description && (
                    <p className="mt-1 text-xs text-red-500">
                      {translatedErrors.description.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-8 sm:gap-2">
                <Button
                  variant="outline"
                  onClick={() => setIsCreating(false)}
                  className="px-2.5 sm:px-4 py-1 sm:py-2 bg-white text-gray-700 border border-gray-200 rounded-lg
                  hover:bg-gray-50 text-xs sm:text-sm"
                >
                  {t("creationFooter.cancel")}
                </Button>
                <Button
                  type="submit"
                  disabled={!form.getFieldState("name") || isLoading}
                  isLoading={isLoading}
                  className="grow px-2.5 sm:px-4 py-1 sm:py-2 bg-white text-gray-700 border border-gray-200 rounded-lg
                  hover:bg-gray-50 flex items-center gap-1 sm:gap-2 text-xs sm:text-sm"
                >
                  <PlusCircleIcon className="w-4 h-4" />
                  {t("creationFooter.createFinal")}
                </Button>
              </div>
            </form>
          </div>
        ) : (
          <div className="w-full flex gap-1.5 sm:gap-2">
            <Button
              onClick={() => setIsCreating(true)}
              variant="outline"
              className="grow px-2.5 sm:px-4 py-1 sm:py-2 bg-white text-gray-700 border border-gray-200 rounded-lg
              hover:bg-gray-50 flex items-center gap-1 sm:gap-2 text-xs sm:text-sm"
            >
              <PlusCircle size={14} className="sm:size-[18px]" />
              {t("creationFooter.create")}
            </Button>
          </div>
        )}

        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          className="w-full px-2.5 sm:px-4 py-1 sm:py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-lg
          flex items-center justify-center gap-1 sm:gap-2 text-xs sm:text-sm"
        >
          {t("creationFooter.done")}
        </Button>
      </div>
    </div>
  );
}
