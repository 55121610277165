import { Collection } from "@/prisma/schema/mysql";
import SelectionSkeleton from "./SelectionSkeleton";
import { Check, FolderIcon } from "lucide-react";
import { SelectedCollection } from "./CollectionSelection";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toast } from "@/components/ui";

interface CSLProps {
  isLoading: boolean;
  collections: SelectedCollection[];
  pageSize: number;
  setCollections: React.Dispatch<React.SetStateAction<SelectedCollection[]>>;
  gameId: string;
}

type RefType = {
  unmountFunction: () => void;
};

const CollectionSelectionList = forwardRef<RefType, CSLProps>(
  ({ isLoading, collections, pageSize, setCollections, gameId }, ref) => {
    const [selectedCollections, setSelectedCollections] = useState<Set<string>>(
      new Set(
        collections
          .filter((collection) => collection.selected)
          .map((collection) => collection.id),
      ),
    );

    useEffect(() => {
      setSelectedCollections(
        new Set(collections.filter((col) => col.selected).map((col) => col.id)),
      );
    }, [collections]);

    const mutationFn = async (updates: { id: string; selected: boolean }[]) => {
      const res = await axios.patch(
        `/api/game/${gameId}/collections_select`,
        updates,
      );
      return res.data;
    };

    const {
      error,
      isError,
      isLoading: isLoadingMutation,
      mutate,
    } = useMutation({
      mutationFn,
      mutationKey: ["collection_games", gameId, collections, pageSize],
      onError: (error) => {
        console.error(error);
        toast({
          title: "Error",
          description: "Failed to update collection selection",
          variant: "destructive",
        });
      },
    });

    const componentWillUnmount = useRef(false);

    // This is componentWillUnmount
    useEffect(() => {
      return () => {
        componentWillUnmount.current = true;
      };
    }, []);

    const unmountFunction = () => {
      const updates = collections.map((col) => ({
        id: col.id,
        selected: selectedCollections.has(col.id),
      }));

      // If no changes, don't send API call
      const shouldSendApiCall = updates.some(
        (update) =>
          update.selected !==
          collections.find((col) => col.id === update.id)?.selected,
      );
      if (!shouldSendApiCall) return;
      // Send one API call with all changes
      mutate(updates);
    };

    useEffect(() => {
      return () => {
        if (componentWillUnmount.current) {
          unmountFunction();
        }
      };
    }, [collections, selectedCollections]);

    useImperativeHandle(ref, () => {
      return { unmountFunction: unmountFunction };
    });

    const onClick = (collectionId: string) => {
      setSelectedCollections((prev) => {
        const newSet = new Set(prev);
        if (newSet.has(collectionId)) {
          newSet.delete(collectionId);
        } else {
          newSet.add(collectionId);
        }
        return newSet;
      });
    };

    return (
      <div className="w-full min-h- mt-4 lg:space-y-2">
        {isLoading && <SelectionSkeleton pageSize={pageSize} />}
        {!isLoading &&
          collections.map((collection: SelectedCollection) => {
            const isSelected = selectedCollections.has(collection.id);
            return (
              <div
                key={collection.id}
                className="w-full group flex items-center justify-between p-2 lg:p-3 rounded-lg
         hover:shadow-sm transition-all duration-200 cursor-pointer
         border border-transparent hover:border-blue-300"
                onClick={() => onClick(collection.id)}
              >
                <div className="flex gap-3 items-center overflow-hidden flex-1">
                  <div className="bg-blue-100/50 w-8 h-8 rounded-lg  flex items-center justify-center">
                    <FolderIcon size={16} className="text-blue-500" />
                  </div>
                  <span className="w-40 lg:w-96 truncate text-gray-900 text-sm sm:text-base font-medium">
                    {collection.name}
                  </span>
                </div>
                <div
                  className={`w-5 h-5 sm:w-6 sm:h-6 rounded-full flex items-center justify-center
           transition-all duration-200 ${
             isSelected
               ? "bg-brand-secondary text-white ring-2 ring-brand-secondary"
               : "border-2 border-gray-300 group-hover:border-brand-secondary"
           }`}
                >
                  {isSelected && <Check size={14} className="sm:hidden" />}
                  {isSelected && (
                    <Check size={16} className="hidden sm:block" />
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  },
);
export default CollectionSelectionList;
