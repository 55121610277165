import React, { useEffect, useRef, useState } from "react";
import {
  PlusCircle,
  AlignLeft,
  FolderIcon,
  FolderCog2Icon,
} from "lucide-react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useQuery } from "@tanstack/react-query";
import useFiltersAndFilterHandlersStore, {
  createFilterStore,
} from "@/hooks/useFiltersAndFilterHandlersStore";
import {
  filterSchemaCollections,
  parseAllParams,
} from "@/lib/validators/queryParamsFiltersTest";
import axios from "axios";
import { Collection } from "@/prisma/schema/mysql";

import { useWindowSize } from "@/hooks/useWindowResize";
import CollectionSelectionList from "./CollectionSelectionList";
import CreationFooter from "./CreationFooter";
import Pagination from "@/app/[locale]/(dashboard)/components/pagination";
import { useTranslation } from "react-i18next";

export interface SelectedCollection extends Collection {
  selected: boolean;
}

const CollectionSelectorModal = ({
  gameId,
  isOpen,
  setIsOpen,
}: {
  gameId: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) => {
  //TODO - fix filterSchemaCollections logic, it has extra fields that are unneccessary
  const windowWidth = useWindowSize();
  const { t } = useTranslation("collections");

  const unmountFunctionRef = useRef<{
    unmountFunction: () => void;
  }>(null);

  const unmountFunction = () => {
    unmountFunctionRef.current?.unmountFunction();
  };

  const filterStore = createFilterStore(
    filterSchemaCollections,
    false,
    windowWidth[0] > 768
      ? {
          pageSize: 6,
        }
      : { pageSize: 4 },
  );

  const filters = useFiltersAndFilterHandlersStore(filterStore);
  const { page, pageSize } = filters.filters;
  const { prevPage, nextPage, setPageIndex, updateFilters } =
    filters.filterHandlers;

  const onNextPage = () => {
    unmountFunction();
    nextPage();
  };

  const onPrevPage = () => {
    unmountFunction();
    prevPage();
  };

  const onSetPageIndex = (index: number) => {
    unmountFunction();
    setPageIndex(index);
  };

  const onPageSizeChange = (size: number) => {
    unmountFunction();
    updateFilters({
      pageSize: size,
    });
  };

  useEffect(() => {
    if (windowWidth[0] > 768 && pageSize !== 6) {
      updateFilters({
        pageSize: 6,
      });
    } else if (windowWidth[0] <= 768 && pageSize !== 4) {
      updateFilters({
        pageSize: 4,
      });
    }
  }, [windowWidth]);

  const [collections, setCollections] = useState<SelectedCollection[]>([]);

  const {
    isLoading,
    isError: isErrorFetch,
    data,
  } = useQuery<{ collections: SelectedCollection[]; count: number }>({
    queryKey: [`game_collections`, { gameId, isOpen, page, pageSize }],
    queryFn: async () => {
      const filters = {
        page,
        pageSize,
      };
      const params = parseAllParams(filterSchemaCollections, filters);
      if (!isOpen) return { collections: [], count: 0 };
      const response = await axios.get(
        `/api/game/${gameId}/collections_select`,
        { params },
      );
      console.log("params", params);
      return response.data;
    },
    refetchOnWindowFocus: false,
    enabled: isOpen,
    onSuccess: (data: { collections: SelectedCollection[]; count: number }) => {
      setCollections(data.collections);
    },
  });

  if (!isOpen) return null;
  console.log("isLoading", isLoading);

  const totalPages = Math.ceil((data?.count ?? 0) / pageSize);

  return (
    <div className="p-1 w-screen lg:w-max">
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          className="lg:w-max bg-white lg:max-w-none max-w-[95%]"
          onClick={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        >
          <div className="w-full lg:w-[40rem]">
            <div className="pb-2 px-3 lg:pb-4 border-b border-gray-100">
              <div className="w-full justify-start flex gap-3 text-md lg:text-xl font-semibold text-slate-600">
                <FolderCog2Icon className="text-blue-500" size={24} />
                <p>{t("manage")}</p>
              </div>
              <p className="text-sm text-slate-500 mt-1">
                {t("manageDescription")}
              </p>
            </div>

            <CollectionSelectionList
              isLoading={isLoading}
              collections={collections}
              pageSize={pageSize}
              setCollections={setCollections}
              gameId={gameId}
              ref={unmountFunctionRef}
            />
            <div className="px-2 sm:px-3 text-slate-600 ml-0">
              <Pagination
                page={page}
                pageSize={pageSize}
                totalPages={Math.ceil((data?.count ?? 0) / pageSize)}
                onPrevPage={onPrevPage}
                onPageSizeChange={onPageSizeChange}
                onNextPage={onNextPage}
                setPageIndex={onSetPageIndex}
              />
            </div>
            <CreationFooter
              setCollections={setCollections}
              collections={collections}
              setPageIndex={setPageIndex}
              pageSize={pageSize}
              setIsOpen={setIsOpen}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CollectionSelectorModal;
