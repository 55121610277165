import React from "react";

const SelectionSkeleton = ({ pageSize }: { pageSize: number }) => {
  const skeletonItems = Array(pageSize).fill(null);

  return (
    <div className="w-full space-y-2">
      {skeletonItems.map((_, index) => (
        <div
          key={index}
          className="w-full flex items-center justify-between p-3 rounded-lg bg-gray-50"
        >
          <div className="w-[80%]">
            <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="w-6 h-6 rounded-full bg-gray-200 animate-pulse" />
        </div>
      ))}
    </div>
  );
};

export default SelectionSkeleton;
