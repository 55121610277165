import {
  countries,
  getEmojiFlag,
  TCountryCode,
  TLanguageCode,
} from "countries-list";
import { capitalizeString } from "../utils";
import { getLocalizedLanguageName } from "../i18n/translationUtils";

// Define the structure of our translation mapping
type TranslationMapping = {
  [key: string]: {
    translation: Record<string, string>;
    special_cases: Record<string, string>;
    bcp47Code: ExtendedLanguageCode;
  };
};

type TranslationOption = keyof typeof mt;

export type ExtendedLanguageCode = TLanguageCode | "scn";

export type ProcessedLanguage = {
  code: ExtendedLanguageCode;
  englishName: string;
  localizedName: string;
  nativeName: string;
  flag: string;
  characterSet: string;
  searchTerms: string[];
};

export const mt: TranslationMapping = {
  ro: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      N: "B",
      C: "N",
    },
    special_cases: {
      N: "c",
      Q: "d",
    },
    bcp47Code: "ro",
  },
  en: {
    translation: {
      K: "K",
      Q: "Q",
      R: "R",
      B: "B",
      N: "N",
    },
    special_cases: {
      B: "b",
    },
    bcp47Code: "en",
  },
  ge: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "de",
  },
  fr: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      F: "B",
      C: "N",
    },
    special_cases: {
      N: "c",
      B: "f",
      Q: "d",
    },
    bcp47Code: "fr",
  },
  sp: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      A: "B",
      C: "N",
    },
    special_cases: {
      N: "c",
      B: "a",
      Q: "d",
    },
    bcp47Code: "es",
  },
  it: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      A: "B",
      C: "N",
    },
    special_cases: {
      N: "c",
      B: "a",
      Q: "d",
    },
    bcp47Code: "it",
  },
  po: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      B: "B",
      C: "N",
    },
    special_cases: {
      N: "c",
      B: "b",
      Q: "d",
    },
    bcp47Code: "pt",
  },
  cz: {
    translation: {
      K: "K",
      D: "Q",
      V: "R",
      S: "B",
      J: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "cs",
  },
  sl: {
    translation: {
      K: "K",
      D: "Q",
      V: "R",
      S: "B",
      J: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "sk",
  },
  tu: {
    translation: {
      S: "K",
      V: "Q",
      K: "R",
      F: "B",
      A: "N",
    },
    special_cases: {
      N: "a",
      B: "f",
    },
    bcp47Code: "tr",
  },
  az: {
    translation: {
      S: "K",
      V: "Q",
      T: "R",
      F: "B",
      A: "N",
    },
    special_cases: {
      N: "a",
      B: "f",
    },
    bcp47Code: "az",
  },
  fi: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      R: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "fi",
  },
  da: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "da",
  },
  no: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "no",
  },
  si: {
    translation: {
      R: "K",
      D: "Q",
      T: "R",
      A: "B",
      S: "N",
    },
    special_cases: {
      B: "a",
      Q: "d",
    },
    bcp47Code: "scn",
  },
  cr: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "hr",
  },
  slo: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "sl",
  },
  se: {
    translation: {
      K: "K",
      D: "Q",
      T: "R",
      L: "B",
      S: "N",
    },
    special_cases: {
      Q: "d",
    },
    bcp47Code: "sr",
  },
};

// Preferred country bcp47 code mappings
// To avoid the flag of e.g. U.K. ending up the flag of whatever other country
//   that has english as a national language shows up first
export const preferredCountries: Partial<
  Record<ExtendedLanguageCode, TCountryCode>
> = {
  ar: "SA",
  bg: "BG",
  bn: "BD",
  cs: "CZ",
  da: "DK",
  de: "DE",
  el: "GR",
  en: "GB",
  es: "ES",
  et: "EE",
  fi: "FI",
  fr: "FR",
  hi: "IN",
  hr: "HR",
  hu: "HU",
  is: "IS",
  it: "IT",
  ja: "JP",
  ko: "KR",
  lt: "LT",
  lv: "LV",
  mt: "MT",
  nl: "NL",
  no: "NO",
  pl: "PL",
  pt: "PT",
  ro: "RO",
  ru: "RU",
  sk: "SK",
  sl: "SI",
  sr: "RS",
  sv: "SE",
  th: "TH",
  tr: "TR",
  uk: "UA",
  vi: "VN",
  zh: "CN",
  sq: "AL",
  ba: "BA",
  mk: "MK",
  scn: "IT",
};

// Helper function to get supported languages in BCP 47 format
export const getSupportedLanguages = (): ExtendedLanguageCode[] => {
  return Object.values(mt).map((config) => config.bcp47Code);
};

// Function to convert BCP 47 code to database language code
export const getBcp47ToDbCode = (bcp47Code: string): string | undefined => {
  const entry = Object.entries(mt).find(
    ([_, config]) => config.bcp47Code === bcp47Code,
  );
  return entry ? entry[0] : undefined;
};

// Function to convert database language code to BCP 47 code
export const getDbCodeToBcp47 = (dbCode: string): string | undefined => {
  return mt[dbCode]?.bcp47Code;
};

// Function to get unicode flag for a language code
export const getLanguageCodeFlag = (
  languageCode: TLanguageCode | null | undefined,
) => {
  if (!languageCode) return "🌐";

  const country = Object.entries(countries).find(([_, countryData]) =>
    countryData.languages.includes(languageCode),
  );

  if (preferredCountries[languageCode]) {
    return getEmojiFlag(preferredCountries[languageCode]);
  } else if (country) {
    return getEmojiFlag(country[0] as TCountryCode);
  }

  return "🌐";
};

// Get the chess character set for a code
export const getCharacterSet = (dbCode: string): string => {
  if (!mt[dbCode]) return "";
  return Object.keys(mt[dbCode].translation).join("/");
};

// Function to process a list of bcp47 language codes and create a list of language objects
export const getProcessedLanguages = (
  languageCodes: ExtendedLanguageCode[],
  locale: string,
): ProcessedLanguage[] => {
  return languageCodes
    .map((bcp47Code) => {
      const dbCode = getBcp47ToDbCode(bcp47Code);
      const languageCode = bcp47Code as TLanguageCode;

      const flag = getLanguageCodeFlag(languageCode);
      const localizedName = capitalizeString(
        getLocalizedLanguageName(locale, languageCode),
      );
      const nativeName = capitalizeString(
        getLocalizedLanguageName(bcp47Code, bcp47Code),
      );
      const englishName = capitalizeString(
        getLocalizedLanguageName("en", languageCode),
      );
      const characterSet = getCharacterSet(dbCode || "");

      const language = {
        code: bcp47Code,
        englishName,
        localizedName,
        nativeName,
        flag,
        characterSet,
        searchTerms: [englishName, nativeName, localizedName, bcp47Code],
      };

      return language;
    })
    .filter(Boolean)
    .sort((a, b) => a.localizedName.localeCompare(b.localizedName, locale));
};

export const translateMove = (
  move: string,
  from: TranslationOption,
  to: TranslationOption,
): string => {
  if (!(from in mt) || !(to in mt)) {
    throw new Error("Invalid language code provided");
  }

  const standardNotation = translateMoveToStandard(move, from);
  return translateMoveFromStandard(standardNotation, to);
};

export const translateMoveToStandard = (
  move: string,
  from: TranslationOption,
): string => {
  if (from === "en") return move;

  const fromDict = mt[from].translation;

  return move
    .split("")
    .map((char) => {
      if (char in fromDict) {
        const translated = fromDict[char];
        return translated;
      }
      return char;
    })
    .join("");
};

export const translateMoveFromStandard = (
  move: string,
  to: TranslationOption,
): string => {
  if (to === "en") return move;

  const toDict = mt[to];
  const reverseTranslation: Record<string, string> = {};

  Object.entries(toDict.translation).forEach(([key, value]) => {
    reverseTranslation[value] = key;
  });

  return move
    .split("")
    .map((char) => {
      if (char in reverseTranslation) {
        const translated = reverseTranslation[char];
        return translated;
      }
      return char;
    })
    .join("");
};
