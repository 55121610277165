import { INFINITE_SCROLL_PAGINATION_RESULTS } from "@/lib/constants";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { memo, useMemo } from "react";
import { Button } from "@/components/ui/button";

import {
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  page: number;
  pageSize: number;
  allPageSizes?: number[];
  totalPages?: number;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  onPageSizeChange: (val: number) => void;
  setPageIndex: (val: number) => void;
}
interface PreventDefault {
  preventDefault(): void;
  stopPropagation(): void;
}
function preventDefaultEvent<T extends PreventDefault>(
  handler?: (e: T) => void,
) {
  return (evt: T) => {
    evt.preventDefault();
    evt.stopPropagation();
    handler && handler(evt);
  };
}

export const DEFAULT_PAGE_SIZES = [
  10,
  20,
  30,
  40,
  50,
  INFINITE_SCROLL_PAGINATION_RESULTS,
];
const Pagination = (props: PaginationProps) => {
  const {
    page,
    pageSize,
    totalPages,
    onPrevPage,
    onNextPage,
    onPageSizeChange,
    setPageIndex,
    allPageSizes,
  } = props;

  const { t } = useTranslation("common");

  const handlePageSize = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onPageSizeChange(Number(e.target.value));
  const pages = (allPageSizes ? allPageSizes : DEFAULT_PAGE_SIZES)
    .filter(Boolean)
    .sort((a, b) => a - b);

  const pagesItems = useMemo(
    () =>
      pages.map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          {pageSize}
        </option>
      )),
    [pages],
  );
  const disabledClass = (cls: string, disable: boolean) => {
    if (!disable) return cls;
    return `${cls} bg-slate-100 pointer-events-none`;
  };

  return (
    <section className="w-full flex flex-row items-center justify-between my-2 text-xs font-medium ">
      {/* <label htmlFor="paginationPagesize" className="whitespace-nowrap">
        Page size:
      </label>
      <select
        id="paginationPagesize"
        className="ml-2 mr-5 p-2  rounded-lg border-2 border-stone-500 border-opacity-30 bg-white  text-stone-700  sm:inline focus:outline-none focus:ring"
        value={pageSize}
        onChange={handlePageSize}
      >
        {pagesItems}
      </select> */}

      <div className="flex items-center justify-center text-sm font-medium mr-2 whitespace-nowrap">
        {t("pagination.pageOf", { page })}&nbsp;<b>{totalPages}</b>
      </div>

      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className={disabledClass(" h-8 w-8 p-0 lg:flex", page <= 1)}
          onClick={preventDefaultEvent(
            page > 1 ? () => setPageIndex(1) : undefined,
          )}
          disabled={page <= 1}
        >
          <span className="sr-only"> {t("pagination.goFirst", { page })}</span>
          <DoubleArrowLeftIcon className="h-4 w-4" />
        </Button>

        <Button
          variant="outline"
          className={disabledClass("h-8 w-8 p-0", page <= 1)}
          onClick={preventDefaultEvent(page > 1 ? onPrevPage : undefined)}
          disabled={page <= 1}
        >
          <span className="sr-only">{t("pagination.goPrev")}</span>
          <ChevronLeftIcon className="h-4 w-4" />
        </Button>

        <Button
          variant="outline"
          className={disabledClass(
            "h-8 w-8 p-0",
            totalPages ? page == totalPages : false,
          )}
          onClick={preventDefaultEvent(
            totalPages && page == totalPages ? undefined : onNextPage,
          )}
          disabled={totalPages ? page == totalPages : false}
        >
          <span className="sr-only">{t("pagination.goNext")}</span>
          <ChevronRightIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="outline"
          className={disabledClass(
            "h-8 w-8 p-0 lg:flex",
            totalPages ? page == totalPages : true,
          )}
          onClick={preventDefaultEvent(() => {
            if (totalPages && page <= totalPages) {
              setPageIndex(totalPages);
            }
          })}
          disabled={totalPages ? page == totalPages : true}
        >
          <span className="sr-only">{t("pagination.goLast")}</span>
          <DoubleArrowRightIcon className="h-4 w-4" />
        </Button>
      </div>
    </section>
  );
};
export default memo(Pagination);
