import { z } from "zod";

export const collectionInput = z.object({
  name: z
    .string({
      required_error: "collections.requiredName",
    })
    .min(5, {
      message: "collections.nameLength",
    })
    .max(100, {
      message: "collections.nameLength",
    }),
  description: z
    .string({ required_error: "collections.requiredDescription" })
    .min(5, {
      message: "collections.descriptionLength",
    })
    .max(100, {
      message: "collections.descriptionLength",
    }),
});

export const collectionInputWithGame = z.object({
  ...collectionInput.shape,
  gameId: z.string().optional(),
});

export type CollectionInputType = z.infer<typeof collectionInput>;
