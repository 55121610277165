import { throttle } from "@/lib/throttle";
import { useCallback, useLayoutEffect, useState } from "react";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const updateSize = useCallback(
    throttle(() => {
      setSize([window.innerWidth, window.innerHeight]);
    }, 500),
    [],
  );
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}
