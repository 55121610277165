import { logger } from "@/lib/logging/logger";
import {
  filterSchema,
  initializeParams,
  parseAllParams,
  filterSchemaCollections,
} from "@/lib/validators/queryParamsFiltersTest";
import { usePathname, useSearchParams, useRouter } from "next/navigation";

import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { z } from "zod";
import { TypeOfUpdateFilters } from "./useFiltersAndFilterHandlers";

type possibleSchemas = z.AnyZodObject;

type NewParamsType = Partial<Record<string, string | number | unknown>>;
interface FilterStore {
  schema: possibleSchemas;
  replace(newParams: NewParamsType): string;
  saveSearchParams(value: string, replace: boolean): void;
  dependencies(): any[];
  getSearchParams(): Object;
}

class RouterFilterStore implements FilterStore {
  private pathname;
  private router;
  private searchParams;
  constructor(public schema: possibleSchemas) {
    this.pathname = usePathname();
    this.router = useRouter();
    this.searchParams = useSearchParams();
  }

  dependencies = (): any[] => {
    return [this.pathname, this.searchParams, this.router];
  };
  replace = (newParams: NewParamsType): string => {
    {
      //Get key value pairs from searchParams
      logger.info(`replace newParams=${JSON.stringify(newParams)}`);
      let keysBeforeUpdate: typeof newParams = {};
      Array.from(this.searchParams.entries()).map((arr) => {
        keysBeforeUpdate[arr[0]] = arr[1];
      });

      //Create an object with the added/modified searchParams from the current function paramter
      const updatedParams = {
        ...keysBeforeUpdate,
        ...newParams,
      };
      if (!updatedParams) {
        logger.info("!updatedParams");
        return this.pathname;
      }
      //Create the searchParams string to update the URL with
      const queryString = Object.keys(this.schema.shape)
        .filter((key) => key in updatedParams && updatedParams[key])
        .map((key) => `${key}=${updatedParams[key]}`)
        .join("&");
      const url = this.pathname + "?" + queryString;
      logger.info(`replace returns ${url}`);
      return url;
    }
  };
  getSearchParams = (): Object => {
    return Object.fromEntries(this.searchParams.entries());
  };

  saveSearchParams = (value: string, replace: boolean = false): void => {
    if (replace) {
      logger.info("router.replace", value);
      this.router.replace(value);
      return;
    }
    logger.info("router.push", value);
    this.router.push(value);
  };
}

function objectToSearchSting(values: object): string {
  return Object.entries(values)
    .filter((arr) => arr.length == 2)
    .map(
      (arr) =>
        `${arr[0]}=${arr[1] ? encodeURIComponent(arr[1].toString()) : ""}`,
    )
    .join("&");
}

class StateFilterStore implements FilterStore {
  private searchParams: Object = {};
  private setSearchParams: Dispatch<SetStateAction<Object>>;
  constructor(
    public schema: possibleSchemas,
    optionalInitialParams?: Object,
  ) {
    const [params, setParams] = useState(optionalInitialParams ?? {});
    this.searchParams = params;
    this.setSearchParams = setParams;
  }
  dependencies = (): any[] => {
    return [this.searchParams];
  };
  replace = (newParams: NewParamsType): string => {
    //Create an object with the added/modified searchParams from the current function paramter
    const updatedParams = {
      ...this.searchParams,
      ...newParams,
    };
    return objectToSearchSting(updatedParams);
  };

  getSearchParams = (): Object => {
    return this.searchParams;
  };
  saveSearchParams = (value: string): void => {
    const entries = value
      .split("&")
      .map((kv) => {
        const parts = kv.split("=");
        if (!parts || parts.length != 2) {
          return null;
        }
        return [parts[0], decodeURIComponent(parts[1])];
      })
      .filter((x) => x != null);
    this.setSearchParams(Object.fromEntries(entries));
  };
}

export function createFilterStore(
  schema: possibleSchemas,
  useRouter: boolean = true,
  optionalInitialParams?: Object,
) {
  const router = useRouter
    ? new RouterFilterStore(schema)
    : new StateFilterStore(schema, optionalInitialParams);
  // const replaced = router.replace(optionalInitialParams || {});
  // router.saveSearchParams(replaced);
  // console.log("router", router.getSearchParams());
  return router;
}

const useFiltersAndFilterHandlersStore = (filterStore: FilterStore) => {
  const replaceWithNewParams = useCallback(
    filterStore.replace,
    filterStore.dependencies(),
  );
  const schema = filterStore.schema;
  const initialParams = useMemo(
    () => initializeParams(schema),
    [schema],
  ) as z.infer<typeof schema>;

  const {
    page,
    pageSize,
    order,
    orderBy,
    gridView,
    privacy,
    sharing,
    pgn,
    username,
    restOfSearch,
    player,
    gameSource,
    opening,
    gameStatus,
    favorite,
    gameOrigin,
    selectedInCollection,
  } = useMemo(() => {
    const returns = parseAllParams(schema, filterStore.getSearchParams());

    return {
      ...initialParams,
      ...returns,
    };
  }, [filterStore.getSearchParams()]);

  const prevPage = useCallback(() => {
    const pageCalc = Number(page) === 1 ? 1 : Number(page) - 1;
    filterStore.saveSearchParams(
      replaceWithNewParams({ page: pageCalc }),
      false,
    );
  }, [page, replaceWithNewParams]);

  const nextPage = useCallback(() => {
    const pageCalc = Number(page) + 1;
    filterStore.saveSearchParams(
      replaceWithNewParams({ page: pageCalc }),
      false,
    );
  }, [page, replaceWithNewParams]);

  const updateFilters: TypeOfUpdateFilters = (filters) => {
    let newParams = parseAllParams(schema, filters);
    if (!Object.keys(newParams).includes("page")) {
      newParams = { ...newParams, page: 1 };
    }
    filterStore.saveSearchParams(filterStore.replace(newParams), true);
  };

  const setPageIndex = useCallback(
    (val: number) => {
      const pageCalc = val.toString();
      filterStore.saveSearchParams(
        replaceWithNewParams({ page: pageCalc }),
        false,
      );
    },
    [page, replaceWithNewParams],
  );

  return {
    filters: {
      page,
      pageSize,
      order,
      orderBy,
      gridView,
      privacy,
      sharing,
      pgn,
      username,
      restOfSearch,
      gameSource,
      gameOrigin,
      player,
      opening,
      gameStatus,
      favorite,
      selectedInCollection,
    },
    filterHandlers: {
      prevPage,
      nextPage,
      setPageIndex,
      updateFilters,
      replaceWithNewParams,
    },
  };
};

export default useFiltersAndFilterHandlersStore;
